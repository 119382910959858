import {p5Engine} from "../../../views/SpaceRocket.vue"

class Rocket{
    constructor(rocket_img, collisionSystem, pos, width = 40, height = 60){
        this.rocket_img = rocket_img
        this.collisionSystem = collisionSystem
        this.pos = pos
        this.width = width;
        this.height = height;
        this.isDead = false
    }

    move(valX, valY) {
        this.pos.set(valX, valY);
    }

    update(asteroids, planets){
        asteroids.forEach(asteroid => {
            this.getGlobalPoints().forEach(point => {
                if(this.collisionSystem.insdidePolygon(point, asteroid.getGloalEdges())) {
                    this.isDead = true
                    // p5Engine.draw()
                }
            });
        });
        planets.forEach(planet => {
            this.getGlobalPoints().forEach(point => {
                if (this.collisionSystem.insdidePolygon(point, planet.getGlobalEdges()) && !planet.collected) {
                    planet.visited = true;
                }
            })
        })
    }

    getGlobalPoints(){
        return [p5Engine.createVector(this.pos.x - this.width/2 + 10, this.pos.y - this.height/2 + 5),
        p5Engine.createVector(this.pos.x - this.width/2 + 10 + this.width - 20, this.pos.y - this.height/2 + 5),
        p5Engine.createVector(this.pos.x - this.width/2 + 10 + this.width - 20, this.pos.y - this.height/2 + 5 + this.height - 5),
        p5Engine.createVector(this.pos.x - this.width/2 + 10, this.pos.y - this.height/2 + 5 + this.height - 5)]
    }

    isAlive(asteroids){
        
    }

    show(){
        p5Engine.push()
        // p5Engine.beginShape()
        // this.getGlobalPoints().forEach(point => {
        //     p5Engine.vertex(point.x, point.y)
        // });
        // p5Engine.endShape()

        // p5Engine.rectMode(p5Engine.CENTER)
        p5Engine.translate(this.pos)
        // p5Engine.fill(200)
        // p5Engine.noStroke()
        // p5Engine.rect(0, 0, this.width, this.height)
        p5Engine.image(this.rocket_img, -this.width/2, -this.height/2, this.width, this.height)
        p5Engine.pop()
    }
}

export {Rocket}