import {p5Engine} from "../../../views/SpaceRocket.vue"

let difficulty = 4;

class Asteroid {

    constructor(pos, size = 60, numEgdes = 0, vel = p5Engine.createVector(0, 3), rot_speed = .01, asteroid_img){
        this.pos = pos
        this.asteroid_img = asteroid_img;
        this.size = size
        this.rot = 0
        this.rot_speed = rot_speed
        this.numEgdes = numEgdes
        this.vel = vel
        this.hardness = p5Engine.map(this.size, 0, 60, 1, difficulty)

        this.points = []

        for(let i = 0; i < this.numEgdes; i++){
            let vec = p5Engine.createVector(1, 0)
            vec.rotate((2*p5Engine.PI / this.numEgdes) * i)
            vec.setMag(p5Engine.random(this.size*.75, this.size*1.25))
            this.points.push(vec)
        }


    }

    static getDifficulty() {
        return difficulty;
    }

    static increaseDifficulty(inc) {
        difficulty += inc;
    }

    createShape(points){
        this.points = points
    }

    damage(projectile){   //damage an asteroid
        this.hardness -= projectile.damage
        return this.hardness <= 0 
    }


    getGloalEdges(){  //return egde coordinates in global coordinates
        let egdes = []
        this.points.forEach(point => {
            let vec = point.copy().add(this.pos)
            // vec.rotate(this.rot)
            egdes.push(vec)
        });
        return egdes
    }


    update(){
        this.rot += this.rot_speed
        this.pos.add(this.vel.copy().mult(p5Engine.deltaTime/16))
    }

    show(){
        p5Engine.push()
        p5Engine.noStroke()
        p5Engine.translate(this.pos)
        p5Engine.rotate(this.rot)

        // p5Engine.fill(100);
        // p5Engine.noStroke();
        
        p5Engine.textureMode(p5Engine.NORMAL)
        p5Engine.texture(this.asteroid_img)
        p5Engine.beginShape();
        this.points.forEach(points => {
            p5Engine.vertex(points.x, points.y, (points.x+this.size)/(8*this.size), (points.y+this.size)/(8*this.size))
        });
        p5Engine.endShape();
        // p5Engine.image(this.asteroid_img, -this.size/2, -this.size/2, this.size, this.size)

        p5Engine.pop()
    }

}

export {Asteroid}