import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import GamingCorner from '../views/GamingCorner.vue'
import MemoryGame from '../views/MemoryGame.vue'
import SpaceRocket from '../views/SpaceRocket.vue'
import News from '../views/News.vue'
import Projects from '../views/Projects.vue'
import Partners from '../views/Partners.vue'
import Contact from '../views/Contact.vue'
import JobFair from '../components/projects/JobFair.vue'
import Login from '../views/Login.vue'
import Join from '../views/Join.vue'
import ChainReaction from '../views/ChainReaction'


const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/news',
    name: 'News',
    component: News
  },
  {
    path: '/projects',
    name: 'Projects',
    component: Projects
  },
  {
    path: '/partners',
    name: 'Partners',
    component: Partners
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact
  },
  {
    path: '/gaming-corner',
    name: 'GamingCorner',
    component: GamingCorner
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/join',
    beforeEnter(to, from, next) {
      window.location.href = 'https://forms.gle/LYtVC8yDfYZPdgYp9';
    }
  },
  {
    path: '/uclani-se',
    beforeEnter(to, from, next) {
      window.location.href = 'https://forms.gle/HJfMeEGejbSZce8c8';
    }
  },
  {
    path: '/memory-game',
    name: 'MemoryGame',
    component: MemoryGame
  },
  {
    path: '/space-rocket',
    name: 'SpaceRocket',
    component: SpaceRocket
  },
  {
    path: '/chainreaction',
    name: 'ChainReaction',
    component: ChainReaction
  },
  {
    path: '/jobfair',
    name: 'JobFair',
    component: JobFair
  },
  
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  mode: 'history',
})

export default router
