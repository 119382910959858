<template>
    <div class="card-container">
        <img v-if="imageName" class="card-picture" :src="require('@/assets/' + imageName + '')" onerror="this.style.display='none'">
        <div class="inner">
            <p class="position">{{ position }}</p>
            <p class="e-mail"><span class="spanEmail">E-mail:</span> {{ email }}</p>
            <p class="phone">Telefon: {{ phone }}</p>
        </div>
    </div>
</template>

<script>

export default {
    name: "ContactCard",
    props: {
        position: String,
        email: String,
        phone: String,
        imageName: String
    }

}

</script>

<style scoped>
    .card-container {
        background-color: white;
        display: grid;
        place-items: center;
        position: relative;
        width: 350px;
        height: 450px;
        aspect-ratio: 1.2;
        box-shadow: 0 5px 12px rgb(0 0 0 / 16.1%);
        border-radius: 35px;
        transition: transform 0.25s;
    }

    .card-container:hover {
      transform: scale(1.05);
      transition: transform 0.25s;
  }

    .card-picture {
        width: 350px;
        height: 350px;
        margin-top: 0px;
        transition: 0.4s;
        border-radius: 10px;   
    }

    .picture-container {
        display: flex;
        align-items: center;
    }

    .inner {
        max-width: 88%;
        position: relative;
        height: 95%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .position {
        position: relative;
        top: 0;
        left: 0;
        font-size: 15px;
        color: #990008;
        margin: 15px 0px 10px 0px;
        align-self: center;
        flex-wrap: wrap;
    }

    .e-mail {
        position: relative;
        top: 0;
        left: 0;
        font-size: 14px;
        color: #990008;
        margin: 0px 0px 10px 0px;
        align-self: center;
        flex-wrap: wrap;
    }

    .spanEmail {
        color: black;
    }

    .phone {
        position: relative;
        top: 0;
        left: 0;
        font-size: 14px;
        margin: 0px 0px 10px 0px;
        align-self: center;
        flex-wrap: wrap;
    }

</style>