<template>
  <div class="carousel-item-container">
      <img :src="require('@/assets/' + img)" alt="Carousel image">
      <slot></slot>
      
  </div>
</template>

<script>

export default {
    name: 'CarouselItem',
    props: {
        img: String
    }
}
</script>

<style scoped>
    .carousel-item-container {
        width: 100%;
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        text-align: center;
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
        z-index: -1;
        filter: brightness(70%);
    }

</style>