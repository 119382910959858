<template>
    <div class="card-container">
        <div class="picture-container" :style="imageStyle">
            <img v-if="imageName" class="card-picture" :src="require('@/assets/' + imageName + '')"
                onerror="this.style.display='none'">
        </div>
        <div class="inner" ref="texthalf">
            <h2 class="title">{{ title }}</h2>
            <p class="text">{{ text }}</p>
            <a :href="link" class="more">
                <p>{{ more }}</p>
            </a>
        </div>
    </div>
</template>

<script>

export default {

    name: "SmallCard",
    props: {
        title: String,
        text: String,
        more: String,
        link: String,
        imageName: String
    },
    data() {
        return {
            imageStyle: {}
        }
    },
}
</script>

<style scoped>
.card-container {
    background-color: white;
    display: grid;
    place-items: center;
    position: relative;
    width: 400px;
    aspect-ratio: 1.2;
    box-shadow: 0 5px 12px rgb(0 0 0 / 16.1%);
    border-radius: 35px;
    opacity: 0.9;
    transition: 0.4s;
}

.card-container:hover {
    opacity: 1;
}

.card-picture {
    width: 380px;
    height: 366px;
    margin-top: 10px;
    border-radius: 25px;
    object-fit: cover;
    aspect-ratio: 1.2;

}

.picture-container {
    /* height: 310px; */
    display: flex;
    align-items: center;
}

.inner {
    max-width: 88%;
    position: relative;
    height: 95%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.title {
    /* position: absolute; */
    top: 0;
    left: 0;
    font-size: 28px;
    color: #990008;
    margin: 15px 0px 10px 0px;
    align-self: flex-start;
}

.text {
    max-width: 90%;
    font-size: 18px;
    line-height: 1.4;
    margin: 10px 0px;
    min-height: 200px;
    align-self: flex-start;
}

.more {
    /* position: absolute; */
    bottom: 0;
    right: 0;
    color: #990008;
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 20px;
    margin-top: 0px;
    align-self: flex-end;
    text-decoration: none;
}
</style>