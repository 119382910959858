<template>
<div>
  <MainSection titleM="PARTNERI" background="mw20.jpg"/>
  <div class="partners-main">
    <div class="partners-name">
      <p >GODIŠNJI POKROVITELJI</p>
    </div>
    <div class="partners-logo-container">
      <!-- <a href="https://www.microsoft.com/serbia/mdcs">
        <div class="partners-logo"><div class="image-partner"><img src="../assets/partners/godisnji/mdcs.png"  style="width:370px"></div></div>
      </a> -->
      <!-- <a href="https://www.se.com/rs/sr/">
        <div class="partners-logo"><img src="../assets/partners/godisnji/Schneider.png" style="width:350px"></div>
      </a> -->
      <!-- <a href="https://www.mainstream.rs/">
        <div class="partners-logo"><img src="../assets/partners/godisnji/mainstream.png" style="width:200px"></div>
      </a> -->
      <!-- <a href="https://www.veriests.com/serbia/">
        <div class="partners-logo"><img src="../assets/partners/godisnji/Veriest.png" style="width:250px"></div>
      </a> -->
      <a href="https://www.nis.rs/">
        <div class="partners-logo"><img src="../assets/partners/godisnji/nis.png" style="width:180px"></div>
      </a>
      <a href="https://www.yettel.rs/">
        <div class="partners-logo"><img src="../assets/partners/edukativni/Yettel.png" style="width:250px"></div>
      </a>  
    </div>
    <div class="partners-name">
      <p>EDUKATIVNI POKROVITELJI</p>
    </div>
    <div class="partners-logo-container">
      <a href="https://www.etf.bg.ac.rs/">
        <div class="partners-logo"><img src="../assets/partners/edukativni/ETF.png" style="width:150px"></div>
      </a>  
    </div>
    <div class="partners-name">
      <p>PARTNERSKE ORGANIZACIJE</p>
    </div>
    <div class="partners-logo-container">
      <a href="https://elektron.org.rs/">
        <div class="partners-logo"><img src="../assets/partners/organizacije/elektron.png" style="width:150px"></div>
      </a>
      <a href="https://best.rs/">
        <div class="partners-logo"><img src="../assets/partners/organizacije/BEST.png" style="width:270px"></div>
      </a>
      <div class="partners-logo"><img src="../assets/partners/organizacije/CRKSEF.png" style="width:180px"></div>
    </div>
  </div>
</div>
</template>

<script>


import MainSection from "../components/MainSection.vue"
import Carousel from '@/components/Carousel.vue'

export default {
  name: "Partners", 
  components: {
      MainSection,
      Carousel}
}

</script>

<style>


@font-face {
  font-family: "RobotoBlack";
  src: local("fonts"),
   url(../assets/fonts/Roboto-Black.ttf) format("truetype");
}

.partners-main{
  background-color: #FA4952;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 25px;
  
}

.partners-name{
  display: flex;
  justify-content: center;
  text-align: center;
  font-family: "RobotoBlack", sans-serif;
  line-height: 1.7;
  font-size: 30px;
  font-weight: bolder;
  color:  #990008;
}

.partners-logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.partners-logo {
  transition: 0.25s;
}

.partners-logo:hover {
  transform: scale(1.1);
  transition: 0.25s;
}

.partners-logo {

    margin: 60px;
  
    align-items: center;
    justify-content: center;
    text-align: center;

}

img {max-width:100%;}

</style>