<template>
  <div>
    <MainSection titleM="STIŽE USKORO!" background="mw20.jpg"/>
  </div>
</template>

<script>
  import MainSection from "../components/MainSection.vue"

  export default {
    name: "Login",
    components:{
      MainSection
    } 
  }
</script>