<template>
    <div class="nav">
        <a href="/">
            <div class="logo">
                <Logo width="130" height="130" color="#fff"/>
            </div>
        </a>
        <div id="nav-bar" class="inner">
            <div class="menu">
                <ul class="menu__list">
                    <li><a href="/">Početna</a></li>
                    <li><a href="/about">O nama</a></li>
                    <!-- <li><a href="/news">Vesti</a></li> -->
                    <li><a href="/projects">Projekti</a></li>
                    <li><a href="/partners">Partneri</a></li>
                    <li><a href="/contact">Kontakt</a></li>
                </ul>
            </div>
            <div class="buttons">
                <a href="/join"><Button class="button">Učlani se</Button></a>
                <!-- <a href="/login"><Button class="button">Prijavi se</Button></a> -->
            </div>
        </div>
        <div class="icon" @click="toggleNav">
            <div class="bar"></div>
            <div class="bar"></div>
            <div class="bar"></div>
        </div>
    </div>
</template>

<script>
import Logo from "@/components/Logo.vue"
import Button from "@/components/Button.vue"

export default {
    name: 'Nav',
    components: {
        Logo,
        Button
    },
    methods: {
      toggleNav (_event) {
        const x = document.getElementById("nav-bar");
        if (x.className === "inner") {
            x.className += " responsive";
        } else {
            x.className = "inner";
        }
      }
  }
}
</script>

<style scoped>

.nav {
    left: 0;
    background: #990008;
    z-index: 2;
    height: 133px;
    /* position: absolute; */
    /* width: 100vw; */
    transition: transform 0.2s;
}

.inner {
    padding-top: 37px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    gap: 55px;
    justify-content: right;
    width: 92%;
}
.menu__list {
    display: flex;
    list-style: none;
    gap: 30px;
    color: white;
    font-size: 18px;
}
.menu__list a{
    color: white;
    text-decoration: none;
    position: relative;
}

.menu__list a:hover{
    color: white;
}

.menu__list a::before {
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  height: 2px;
  margin-bottom: -10px;
  bottom: 0;
  left: 0;
  background-color: #ffffff;
  transform: scaleX(0);
  transition: transform 0.3s ease;
}

.menu__list a:hover::before {
  transform: scaleX(1);
}

.buttons {
    display: flex;
    gap: 30px;
}

.buttons a{
    text-decoration: none;
}

.logo{
    padding-top: 10px;
    padding-left: 75px;
    position: absolute;
    z-index: 3;
}

.icon{
    display: none;
    width: 50px;
    position: absolute;
    right: 55px;
    top: 50px;
}

.bar{
    width: 50px;
    height: 5px;
    background-color: white;
    margin-bottom: 10px;
}

@media screen and (max-width: 1100px) {
    .inner .menu {display: none;}

    .inner .buttons {display: none;}

    .icon {
      float: right;
      display: block;
    }

    .inner.responsive {
      position: absolute;
      height: 100vh;
      width: 100%;
      background-color: #990008;
      opacity: 0.9;
      justify-content: center;
      padding-top: 0;
      flex-direction: column;
    }

  .inner.responsive .menu {
        display: block;
        padding-top: 135px;
    }

    .menu__list{
        flex-direction: column;
        font-size: 20px;
    }

    .inner.responsive .buttons {
        display: block;
        flex-direction: column;
        gap: 30px;
        padding-left: 50px;
    }

    .button {
        margin-bottom: 30px;
    }

    .logo{
        padding-left: 50px;
        padding-top: 5px;
    }

    .inner.responsive a {
        float: none;
        display: block;
        text-align: left;
    }
}

</style>