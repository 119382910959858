import {p5Engine} from "../../../views/SpaceRocket.vue"
import {Asteroid} from "./asteroid.js"

// A simple Particle class
let Particle = function(position, asteroid_shooted, size, vel) {
  this.acceleration = p5Engine.createVector(0, 0.05);
  this.size = size
  this.vel = vel
  this.position = position.copy();
  this.lifespan = 255;

  this.asteroid = new Asteroid(this.position, this.size, p5Engine.random(4, 10), this.vel, p5Engine.random(-.05, .05), asteroid_shooted.asteroid_img)

};

Particle.prototype.run = function() {
  this.update();
  this.display();
};

// Method to update position
Particle.prototype.update = function(){
  // this.velocity.add(this.acceleration);
  this.position.add(this.velocity);
  this.lifespan -= 15;

  this.asteroid.update()
};

// Method to display
Particle.prototype.display = function() {
  p5Engine.tint(255, p5Engine.map(this.lifespan, 0, 255, 200, 255));
  this.asteroid.show()

  // stroke(200, this.lifespan);
  // strokeWeight(2);
  // fill(127, this.lifespan);
  // ellipse(this.position.x, this.position.y, 12, 12);
};

// Is the particle still useful?
Particle.prototype.isDead = function(){
  return this.lifespan < 0;
};

let ParticleSystem = function(position) {
  this.origin = position.copy();
  this.particles = [];
};



ParticleSystem.prototype.addParticles = function(origin, num, asteroid, minSize = 5, maxSize = 10, velx = p5Engine.createVector(-1,1), vely = p5Engine.createVector(1, 3)) {
  for(let i = 0; i<num; i++){
    if(origin) this.particles.push(new Particle(origin, asteroid, 
      p5Engine.random(minSize, maxSize),
      p5Engine.createVector(p5Engine.random(velx.x, velx.y), p5Engine.random(vely.x, vely.y))));
    else this.particles.push(new Particle(this.origin, null));
  }
  
};

ParticleSystem.prototype.addParticle = function() {
  this.particles.push(new Particle(this.origin));
};

ParticleSystem.prototype.show = function() {
  for (let i = this.particles.length-1; i >= 0; i--) {
    let p = this.particles[i];
    p.run();
    if (p.isDead()) {
      this.particles.splice(i, 1);
    }
  }
};


export {ParticleSystem}