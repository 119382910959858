<template>
    <div class="btn">
        <slot></slot>
    </div>
</template>

<style scoped>

    .btn {
        background-color: white;
        padding: 15px;
        border-radius: 8px;
        font-size: 18px;
        color: #e52a30;
        font-weight: bold;
        cursor: pointer;
        transition: 0.4s;
    }

    .btn:hover{
        color: white;
        background-color: #e52a30;
    }

</style>