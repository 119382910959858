import {p5Engine} from "../../../views/SpaceRocket.vue"
import {Projectile} from "./projectile.js"

class Weapon{
    constructor(rocket, maxNumProjectiles = 50){
        this.rocket = rocket
        this.projectiles = []

        this.maxNumProjectiles = maxNumProjectiles
        this.numProjectiles = this.maxNumProjectiles
    }

    update(){
        for (let p in this.projectiles) {
            let projectile = this.projectiles[p]
            if(this.outOfScreen(projectile)) this.projectiles.splice(projectile, 1)
            projectile.update()
        }
    }

    show(){
        this.projectiles.forEach(projectile => {
            projectile.show()
        });
    }

    outOfScreen(projectile){
        return projectile.pos.x < -50 || projectile.pos.x > p5Engine.width + 50 || projectile.pos.y < -50 || projectile.pos.y > p5Engine.height + 50
    }

    fire(){
        this.numProjectiles--
        if(this.numProjectiles < 0){
            this.numProjectiles = 0
            return
        }
        this.projectiles.push(new Projectile(this.rocket.pos.copy()))
    }

    recharge(){
        this.numProjectiles+=3
        if(this.numProjectiles > this.maxNumProjectiles) this.numProjectiles = this.maxNumProjectiles
    }

    updateMaxNumOfPrjectiles(newNumOfProjectiles) {
        this.maxNumProjectiles = newNumOfProjectiles
    }
}

export {Weapon}
