<template>
    <div class="container">
        <div class="filter"></div>
        <img v-if="background" alt="IstekMW" class="image" id="mainImg" :src="require('@/assets/' + background + '')" 
            onerror="this.style.display='none'">
        <!-- <img id="mainImg" src="../assets/mw20.jpg" alt="IstekMW" class="image"> -->
        <h1 class="mainTitle">{{titleM}}</h1>
        <h4 class="tekst1">Udruženje studenata elektrotehnike Evrope (EESTEC)</h4> 
        <h4 class="tekst2">Upravni odbor</h4>   
    </div>
</template>

<script>
export default {
    name: "ContactMainSection",
    props: {
        titleM: String,
        background: String
    }
}
</script>

<style>

@import 'https://assets.stoumann.dk/css/app-filtermaker.css';

.mainTitle{
    margin-top:0px;
    z-index: 1;
    font-family: 'JFont', sans-serif;
    position: absolute;
    color: white;
    text-shadow: var(--text-shadow);
    font-size: 5vw;
    max-width: 85%;
    font-weight: 300;
}

#mainImg {
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: brightness(70%);
}

.container {
    margin-top:-134px ;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.filter{
    top: 0px;
    left: 0px;
    position: absolute;
    width: 100%;
    height: 100vh;
    background: linear-gradient(0deg, rgba(229,42,48,0.6365896700477065) 43.1%, rgba(153,0,8,1) 83%) no-repeat top center;
}

.tekst1{
    margin-top:200px;
    font-family: 'JFont', sans-serif;
    position: absolute;
    color: white;
    text-shadow: var(--text-shadow);
    font-size: 2vw;
    max-width: 65%;
    z-index: 1;
    font-weight: 150;
    font-style: italic;
}

.tekst2{
    margin-top:400px;
    font-family: 'JFont', sans-serif;
    position: absolute;
    color: white;
    text-shadow: var(--text-shadow);
    font-size: 2vw;
    max-width: 65%;
    z-index: 1;
    font-weight: 300;
}

</style>