<template>
    <div class="project">
        <img src="../../assets/ilustrations/crkvaR.png" alt="crkvaR" class="project-ilustration">
        <div class="project-inner">
            <h1 class="project-title">{{title}}</h1>
            <p class="project-text">{{text1}}</p>
            <div class="project-images">
                <img v-if="background1" alt="slika1" class="project-image" :src="require('@/assets/projects/' + background1 + '')" 
                    onerror="this.style.display='none'">
                <img v-if="background2" alt="slika2" class="project-image" :src="require('@/assets/projects/' + background2 + '')" 
                    onerror="this.style.display='none'">
            </div>
            <p class="project-text">{{text2}}
               <br><br> Više o projektu možete naći na sledećem <a href="{{link}}">linku</a></p>
            <div class="project-images">
                <img v-if="background3" alt="slika3" class="project-image" :src="require('@/assets/projects/' + background3 + '')" 
                    onerror="this.style.display='none'">
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: "Project",
    props: {
        title: String,
        text1: String,
        background1: String,
        background2: String,
        text2: String,
        background3: String,
        link: String
    }
}

</script>

<style>

.project-ilustration{
    top: 30%;
    width: 45%;
    position: absolute;
    opacity: 0.3;
    z-index: 0;
}

.project{
    margin: 30px auto;
    display: flex;
    justify-content: center;
    position: relative;
    z-index: 3;
}

.project-inner{
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.project-title{
    font-size: 32px;
    color: #FA4952;
    align-self: flex-start;
}

.project-text{
    line-height: 1.7;
    font-size: 18px;
    margin: 40px 0px;
}

.project-text a{
    text-decoration: none;
    color: #E52A30;
}

.project-images{
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 40px;
    z-index: 2;
}

.project-image{
    width: 430px;
    height: 270px;
    border-radius: 20px;
}

</style>