<template>
    <div class="memory-body">

        <div id="div-shadow">
        <div id="popup-inform" class="div-hidden">
        <h1 id="popup-head" class="head-hidden">Način igranja</h1>
        <span class="popup-txt">Igra počinje kad otvorite prvo polje.</span>
        <span class="popup-txt">Potrebno je da nađete parove slika što brže i koristeći što manje klikova.</span>
        <span class="popup-txt">Najbolji takmičar dobija nagradu!!!</span>
        <span class="popup-txt">Bodovi su negativni.</span>
        <button id="inform-confirm">OK</button>
        </div>

        <div id="div-finished" class ="div-hidden">
            <h1 id="finished-head" class="head-hidden">Čestitamo!!!</h1>
            <span class="popup-txt" id="points"></span>
            
            <!--<span class= "popup-txt" id = "finished-info">Da bi učestvovao u nagradnoj igri, potrebne su nam ove informacije.</span>-->

            <form action="" id ="form-finished">
                <!--<label for="input-name" class = "popup-txt">Ime:</label>-->
                <!--<input type="text" id = "input-name" class = "input-finished" name= "userName" required>-->
                <!--<label for="input-surname" class = "popup-txt" >Prezime:</label>-->
                <!--<input type="text" id = "input-surname" class = "input-finished" name= "userSurname" required>-->
                <!--<label for="input-email" class = "popup-txt">Mejl:</label>-->
                <!--<input type="email" id = "input-email" class = "input-finished" name= "userEmail" required>-->
                <input type="submit" value="Prijavi se" class="input-finished" id="submit-finished" hidden>

            </form>
        </div>
        </div>

        <div class="div-title">
            <h1 class="memory-title"> EESTEC - Igra memorije</h1>
            <hr>
        </div>

        <div class="div-game">
            <table id="game" cellpadding = "0"></table>
        </div>

        <div class="div-bottom">
            <div class="div-button">
                <button id="restart">
                    Restartuj
                </button>
            </div>
            <div class="div-time">
                <label for="time" id="label-time">Vreme: </label>
                <p id="time">00:00</p>
            </div>

        </div>
    </div>
</template>

<script>


class NodeImg
{
    constructor(img, next)
    {
        this.img = img;
        this.next = next;
    }
}

let first;
let second;
let time;
let timerOn;//timer za vreme dok su otvorena polja
let timeStarted;
let remainingPairs;
let stopWatch;
let numberOfTries;
let points;
let headImg;
let listSize;
let arr;

function load(){
    loadTable(4, 5);
    popupInform();
    window.addEventListener('resize', function(event)
{
    let table = document.getElementById("game");
    let row = table.rows.length;
    let col = table.rows[0].cells.length;
    let w = window.innerWidth;
    if(w > window.innerHeight)
    {
        w = window.innerHeight;
    }
    for(let i = 0; i < row; i++)
    {
        for(let j = 0; j < col; j++)
        {

            let colLocal = document.getElementById("col" + (i * col + j));
            colLocal.width = (w * 0.75) / col;
            colLocal.height = colLocal.width;
            if(colLocal.getElementsByTagName("img").length > 0)
            {
                let img = document.getElementById("imgcol" + (i * col + j));
                img.width = (w * 0.75) / col;
                img.height = img.width;
            }
        }
    }

});
    
    setMouseListenerPopupInform();
    setMouseListenerRestart();
    setMouseListenersGame();
    setMouseListenerFinished();

    initVariables();

}

function shuffleImg()
{
    //init
    const arr = new Array(20);
    for(let i = 0; i < 10; i++)
    {
        arr[2 * i] = i;
        arr[2 * i + 1] = i;
    }

    //shuffle
    for(let i = 0; i < 20; i++)
    {
        let j = Math.floor((Math.random() * 20));
        let tmp = arr[i];
        arr[i] = arr[j];
        arr[j] = tmp;
    }

    return arr;

}

function makeAnImageList(arr)
{
    let headListTmp = null;
    for(let i = 0; i < 20; i++)
    {
        let img = document.createElement("img");
        img.classList.add("gameImg");
        // img.id = "img" + "col" + i;
        img.src = require("@/assets/memory-game/img" + arr[i] + ".png");
        img.alt = "None ";
        headListTmp = new NodeImg(img, headListTmp);
        arr[i] = null;
    }
    return headListTmp;
}

function loadTable(row, col)
{
    //const arr = shuffleImg();
    let table = document.getElementById("game");
    let w = window.innerWidth;
    if(w > window.innerHeight)
    {
        w = window.innerHeight;
    }

    for(let i = 0; i < row; i++){

        let rowLocal = table.insertRow(i);
        rowLocal.id = "row" + i;
        for(let j = 0; j < col; j++)
        {
            let colLocal = rowLocal.insertCell(j);
            colLocal.id = "col" + (i * col + j);
            colLocal.classList.add("gameCol");
            //let img = document.createElement('img');

            //img.src = "./images/img" + arr[i * col + j] + ".png";
            //img.id = "img" + colLocal.id;
            colLocal.width = Math.floor((w * 0.75)  / col) ;
            colLocal.height = colLocal.width;
            //img.width = colLocal.width;
            //img.height = colLocal.height;
            //img.classList.add("gameImg");
            //img.style = "visibility: hidden";
            //colLocal.append(img);
        }
    }
}




function popupInform()
{
    document.getElementById("div-shadow").style = "visibility: visible";
    document.getElementById("popup-inform").style = "visibility: visible";
}

function popupInformClose()
{
    //document.getElementById("div-finished").style = "visibility: visible";
    document.getElementById("div-shadow").style = "visibility: hidden";
    document.getElementById("popup-inform").style = "visibility: hidden";
}

function setMouseListenerPopupInform()
{
    document.getElementById("inform-confirm").addEventListener('click', function(event)
    {
        popupInformClose();
    });
}

function setMouseListenerRestart()
{
    document.getElementById("restart").addEventListener('click', function(event)
    {
        location.reload();
    }
    );
}

function setMouseListenersGame()
{
    let arrCol = document.getElementsByClassName("gameCol");

    for(let i = 0; i < arrCol.length; i++)
    {
        arrCol[i].addEventListener('click', function(event)    {
            clickGame(this);
        }
        );
    }
}

function initVariables()
{
    first = {
        value: [null, false, null],
        get curValue()
        {
            return this.value;
        },
        set curValue(value)
        {
            this.value = value;
            
        }
    };

    second = {
        value: [null, false, null],
        get curValue()
        {
            return this.value;
        },
        set curValue(value)
        {
            this.value = value;
            secondOpened();
        }
    };

    time = {
        value: 0,
        get curValue()
        {
            return this.value;
        },
        set curValue(value)
        {
            this.value = value;
            changeTime();
        }
    };

    remainingPairs = 10;
    timerOn = false;
    timeStarted = false;
    numberOfTries = 0;
    points = 0;

    arr = shuffleImg();
    headImg = makeAnImageList(arr);
    listSize = arr.length;
}

function secondOpened()
{
    if(second.curValue[1] == true)
    {
        //numberOfTries++;
        if(first.curValue[0].src != second.curValue[0].src)
        {
            timerOn = true;
            setTimeout(closeOpened, 750);
        }
        else
        {
            remainingPairs--;
            first.curValue = [null, false, null];
            second.curValue = [null, false, null];
            if(remainingPairs == 0)
            {
                stopTime();
                points += time.curValue;
                popupFinished();
            }
        }

    }
}

function closeOpened()
{
    //second.curValue[0].style = "visibility: hidden";
    //first.curValue[0].style = "visibility: hidden";

    first.curValue[2].removeChild(first.curValue[0]);
    second.curValue[2].removeChild(second.curValue[0]);
    first.curValue[2].style = "background-color: transparent";
    second.curValue[2].style = "background-color: transparent";

    first.curValue = [null, false, null];
    second.curValue = [null, false, null];
    timerOn = false;
}

function clickGame(clicked)
{
    if(timeStarted == false)
    {
        timeStarted = true;
        startTime();
    }
    if((first.curValue[1] == false || second.curValue[1] == false) && timerOn == false)
    {
        if(clicked.getElementsByTagName("img").length == 0)
        {
            points += 1;
            clicked.style = "background-color: #fcf9f8";
            if(arr[getColNum(clicked.id)] == null)
            {
                let j = Math.floor((Math.random() * 20)) % listSize;
                listSize--;
                let tmp = headImg;
                let prev = null;
                for(let i = 0; i < j; i++)
                {
                    prev = tmp;
                    tmp = tmp.next;
                }

                if(prev != null) 
                {
                    prev.next = tmp.next;
                }
                else
                {
                    headImg = tmp.next;
                }

                arr[getColNum(clicked.id)] = tmp.img;
                tmp.img.id = "img" + clicked.id;

            }
            let image = arr[getColNum(clicked.id)];
            image.width = clicked.width;
            image.height = clicked.height;
            clicked.append(image);
            if(first.curValue[1])
            {
                second.curValue = [image, true, clicked];
            }
            else
            {
                first.curValue = [image, true, clicked];
            }

        }
            
        //let image = document.getElementById("img" + clicked.id);
       /* if(window.getComputedStyle(image).visibility == "hidden")
        {
            //image.style = "visibility:visible";
            //clicked.style = "background-color: #fcf9f8";
            if(first.curValue[1])
            {
                second.curValue = [image, true, clicked];
            }
            else
            {
                first.curValue = [image, true, clicked];
            }
        }*/
    }

}

function startTime()
{
    stopWatch = setInterval(incTime, 1000);
}

function incTime()
{
    time.curValue = time.curValue + 1;
}

function changeTime()
{
    let par = document.getElementById("time");
    let min = Math.floor(time.curValue / 60);
    let sec = time.curValue % 60;
    if(min < 10)
    {
        min = '0' + min;
    }
    if(sec < 10)
    {
        sec = '0' + sec;
    }

    par.innerHTML = min + ":" + sec;

}

function stopTime()
{
    clearInterval(stopWatch);
}

function setMouseListenerFinished()
{
    document.getElementById("submit-finished").addEventListener("click", function(event){
        event.preventDefault();
        let url = "https://script.google.com/macros/s/AKfycbydLCqQw-NaO_2AptoZ8NHIstFhNHSzX63JKVfPvBDdMZ2YKwyoSeNTxaIHnbcjwy7B/exec";

        let xhr = new XMLHttpRequest();
        xhr.open("POST", url);

        //xhr.setRequestHeader("Accept", "application/json");
        //xhr.setRequestHeader("Content-Type", "application/json");
        //xhr.setRequestHeader("Access-Control-Allow-Origin", "application/json");

      /*  xhr.onreadystatechange = function () {
        if (xhr.readyState === 4) {
            console.log(xhr.status);
            console.log(xhr.responseText);
        }};*/


        const d = new Date();
        let hh = d.getHours();
        if(hh < 10)
        {
            hh = "0" + hh;
        }
        let mm = d.getMinutes();
        if(mm < 10)
        {
            mm = "0" + mm;
        }

        let ss = d.getSeconds();
        if(ss < 10)
        {
            ss = "0" + ss;
        }

        let dd = d.getDate();
        if(dd < 10)
        {
            dd = "0" + dd;
        }

        let mo = d.getMonth() + 1;
        if(mo < 10)
        {
            mo = "0" + mo;
        }

        let yy = d.getFullYear();

        var data = `{
        "userName": "` + document.getElementById("input-name").value + `" ,
        "userSurname": "` + document.getElementById("input-surname").value + `",
        "userEmail": "` + document.getElementById("input-email").value + `",
        "userPoints": "` + points + `",
        "userTime": "` + hh + 
        `:` + mm +
        `:` + ss + 
        ` ` + dd + 
        `.` + mo + 
        `.` + yy + `"
        }`;

        xhr.send(data);
        document.getElementById("div-shadow").style = "visibility: hidden";
        document.getElementById("div-finished").style = "visibility: hidden";

    });
}

/*function timeScale()
{
    if(time.curValue < 20)
    {
        return 1.1;
    }
    else if(time.curValue < 30)
    {
        return 1;
    }
    else if(time.curValue < 45)
    {
        return 0.9;
    }
    else if(time.curValue < 60)
    {
        return 0.8;
    }
    else if(time.curValue < 90)
    {
        return 0.7;
    }
    else if(time.curValue < 120)
    {
        return 0.6;
    }
    else
    {
        return 0.5;
    }
}*/

/*function addNewRow(name, surname, email, points)
{
    localStorage.setItem(localStorage.length + 1, (localStorage.length + 1) + "," + name + "," + surname + "," + email + "," + points + "\n");
}*/

function popupFinished()
{
    //points =  Math.floor(points * 20 / numberOfTries);
    document.getElementById("points").innerHTML = "Imali ste " + points + " poena!";
    document.getElementById("div-shadow").style = "visibility: visible";
    document.getElementById("div-finished").style = "visibility: visible";
}

/*function downloadFile()
{
    let text = "";
    for(let i = 0; i < localStorage.length; i++)
    {
        text += localStorage.getItem(i + 1);
    }
    let file = new Blob([text], {type: 'text/csv'});
    let url = URL.createObjectURL(file);
    window.open(url, "_blank");
    localStorage.clear();
}*/


function getColNum(id)
{
    return parseInt(id.slice(3));
}

export default {
    name: "MemoryGame",
    mounted() {
        window.addEventListener("load", () => load());
    }
}

</script>

<style>

.memory-title{
    text-align: center;
    font-size: calc(8px + 4vh);
    text-transform: uppercase;
    font-family: 'Roboto', sans-serif;
    background-color: #fcf9f8;
    margin-top: 0px;
    padding-top: 20px;
}

.memory-body{
    background-color: #fcf9f8;
    padding-bottom: 30px
}

.div-game{
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 6vh;
}

.gameCol{
    border: 3px solid black;
    padding: 0;
}

#game{
    background-image: url(../assets/memory-game/Istek.png);
    background-size: cover; 
    background-position: center;
    border:solid 2px orange;
    border-collapse: collapse;
}

.gameImg{
    display: block;
    object-fit: contain;
}

.div-bottom{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content:space-between;

    padding-top: 3vh;
    font-family: 'Roboto', sans-serif;
}

.div-time{
    width: 100%;
    display: flex;
    justify-content: center;
}

.div-button{
    width: 100%;
    display: flex;
    justify-content: center;
}

#time{
    margin: 0;
    padding-left: 5px;
    font-size: calc(10px + 3vh);;
}

#label-time{
    font-size: calc(10px + 3vh);
}

#restart{
    font-size: calc(10px + 3vh);
    font-family:  Roboto, sans-serif;
    border-radius: 6px;
}

#restart:hover{
    border: 3px solid rgb(9, 190, 9);
}

.div-hidden{
    visibility: hidden;
    display: flex;
    top: calc(15% + 2vh);
    left: 20%;
    justify-content: left;
    flex-direction: column;
    position:absolute;
    width: 60%;
    background-color:rgb(236, 236, 245);
    font-family: Roboto, sans-serif;
    border-radius: 6px;
}

.head-hidden{
    padding-left: 10px;
    font-size: calc(3vw + 1vh);
}

.popup-txt{
    padding-left: 10px;
    font-size: calc(1vw + 1vh + 2pt);
}

#inform-confirm{
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 70%;
    margin-right: 10%;
    border-radius: 3px;
    height: 5vh;
}

#inform-confirm:hover{
    border:2px solid rgb(9, 190, 9);
}

#div-shadow{
    visibility: hidden;
    width:100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    background-color: rgba(0,0,0,0.4);
}

#form-finished{
    display:flex;
    flex-direction:column;
    width : calc(50% + 2vh);
}

.input-finished{
    margin-left: 10px;
    margin-bottom: 10px;
    font-size: calc(1vw + 1vh);
}

#submit-finished{
    margin-top: (1vw + 5px);
    margin-bottom: 20px;
    border-radius: 3px;
    height: 4vh;
}

#finished-info{
    margin-bottom: 15px;
}

#submit-finished:hover{
    border:2px solid rgb(9, 190, 9);
}

</style>