<template>
    <div class="news">
        <img src="../../assets/ilustrations/muzejW.png" alt="teslaM" id="teslaM">
        <p id="newsTitle">Novosti</p>
        <div class="newsPanels">
            <SmallCard v-for="(newsItem, index) in getShortArray()" :key="index" :title="newsItem.title"
                :text="newsItem.text" :more="newsItem.more" :link="newsItem.link">
            </SmallCard>
        </div>
        <a class="prev" @click="prev">&#10094;</a>
        <a class="next" @click="next">&#10095;</a>
        <div class="dots">
            <span class="dot" id="bigScreen" @click="currentSlide(0)"></span>
            <span class="dot" id="bigScreen" @click="currentSlide(1)"></span>
            <span class="dot" id="bigScreen" @click="currentSlide(2)"></span>
            <span class="dot" id="smallScreen" @click="currentSlide(3)"></span>
            <span class="dot" id="smallScreen" @click="currentSlide(4)"></span>
        </div>
    </div>
</template>

<script>

import SmallCard from "../SmallCard.vue";

export default {

    name: "News",
    components: {
        SmallCard
    },
    data() {
        return {
            news: [
                {
                    title: "Ovde ka gaming corner-u!",
                    text: "Gaming corner je sekcija na ovom sajtu gde možete pogledate neke od zanimljivih igrica koje su pravili članovi Isteka u prethodnom periodu. Pristup ovoj sekciji je moguć samo ako se prijavite na sajtu!",
                    more: "Pročitaj više",
                    link: "/gaming-corner"
                },
                {
                    title: "Jobfair - 4. i 5. novembar 2024.",
                    text: "“Jobfair - Kreiraj svoju budućnost!” je najveći sajam poslova i praksi u regionu, ujedno i naš najveći projekat, koji realizujemo zajedno sa prijateljskom organizacijom BEST Beograd. Sajam ima tradiciju dugačku 20 godina, održava se svakog prvog ponedeljka i utorka u novembru u Zgradi tehničkih fakulteta, a od 2022. Godine i na Mašinskom fakultetu.",
                    more: "Pročitaj više",
                    link: "/projects"
                },
                {
                    title: "Brand New Engineers",
                    text: "“Brand New Engineers - Od integrala do inženjera” je projekat koji Istek LK Beograd organizuje od 2010. godine, namenjen je studentima i sastoji se iz hakatona i konferencije. Konferenciju čine predavanja iz oblasti elektrotehnike i računarstva. Hakaton se održava u saradnji sa kompanijama i predstavlja takmičenje iz oblasti robotike, logike i kodovanja u trajanju od 24h.",
                    more: "Pročitaj više",
                    link: "/projects"
                },
                {
                    title: "Soft Skills Academy",
                    text: "„Soft Skills Academy – Unapredi svoje sposobnosti!“ predstavlja skup interaktivnih radionica čiji je cilj unapređivanje mekih veština kod studenata. Treninge drže sertifikovani Istek treneri i predstavnici vodećih kompanija u regionu. Akademija mekih veština se prvi put održala upravo u Beogradu, 2010. godine i do danas se organizovala u više od 20 država širom Evrope!",
                    more: "Pročitaj više",
                    link: "/projects"
                },
               
                {
                    title: "EESTech Challange",
                    text: "EESTech Challange je takmičenje internacionalnog karaktera nastalo 2017. godine, namenjeno studentima. Takmičenje se održava u dva nivoa - lokalna runda i finalna runda.",
                    more: "Pročitaj više",
                    link: "/projects"
                }
            ],
            i: 0
        }
    },
    methods: {
        next() {
            this.i += 1;
            this.$forceUpdate();
        },
        prev() {
            this.i -= 1;
            this.$forceUpdate();
        },
        currentSlide(n) {
            this.i = n;
            this.$forceUpdate();
        },
        getShortArray() {
            return [this.news[this.i % this.news.length], this.news[(this.i + 1) % this.news.length], this.news[(this.i + 2) % this.news.length]]
        }

    }

}

</script>

<style>
.news {
    padding-top: 40px;
    position: relative;
    background-color: #FA4952;
    padding-bottom: 150px;
}

#newsTitle {
    padding-top: 5px;
    padding-bottom: 35px;
    margin-left: 9%;
    font-size: 50px;
    color: white;
}

.newsPanels {
    gap: 30px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

#teslaM {
    left: 56%;
    top: 27%;
    opacity: 0.5;
    position: absolute;
    width: 40vw;
}

.prev,
.next {
    cursor: pointer;
    position: absolute;
    top: 50%;
    width: auto;
    padding: 16px;
    color: white;
    font-weight: bold;
    font-size: 18px;
    transition: 0.7s ease;
    border-radius: 0 4px 4px 0;
    text-decoration: none;
    user-select: none;
}

.next {
    right: 5px;
}

.prev {
    left: 5px;
}

.a {
    top: 5px;
    bottom: 5px;
}

.prev:hover,
.next:hover {
    background-color: #ec7f84;
}

.dots{
    text-align: center;
    margin-top: 20px;
}

.dot {
    top: 10px;
    cursor: pointer;
    height: 15px;
    width: 15px;
    margin: 0 2px;
    background-color: rgb(255, 255, 255);
    border-radius: 50%;
    transition: background-color 0.6s ease;
}

#bigScreen {
    display: inline-block;
}


#smallScreen {
    display: none;
}

.active,
.dot:hover {
    background-color: #717171;
}

@media screen and (max-width: 700px) {

    .prev,
    .next {
        display: none;
    }

    #Second {
        display: none;
    }

    #smallScreen {
        display: inline-block;
    }

}
</style>
