<template>
    <div class="outer-container news-bg">
        <div class="news-grid">
            <TransitionGroup name="grid">
            <div v-for="(newsColumn, index) in newsColumns" :key="'col-' + index" class="news-column">
            <TransitionGroup name="grid">
                <div v-for="(news, index) in newsColumn" :key="news.title + index" class="news-item">
                    <SmallCard :title="news.title" :text="news.text" :more="news.more" :link="news.link"
                        :imageName="news.imageName" :ref="'news'" />
                </div>
            </TransitionGroup>
            </div>
        </TransitionGroup>
        </div>
        <div class="load-more-icon" ref="loadMore">
            <img @click="loadMoreNews" src="../assets/ilustrations/load-more-icon.svg" alt="">
        </div>
    </div>
</template>

<script>
import SmallCard from './SmallCard.vue';
export default {
    name: "AllNews",
    components: {
        SmallCard
    },
    data() {
        return {
            newsArray: [],
            gridSize: 6
        }
    },
    mounted() {
        this.newsArray = this.fetchNews(this.newsArray, this.gridSize);
    },
    computed: {
        newsColumns() {
            var numNewsImages = 0
            var columns = [[], [], []]
            var colLen = [0, 0, 0]

            this.newsArray.forEach((elem) => {
                if (elem.imageName != null)
                    numNewsImages++
            })

            var unevennes = (numNewsImages * 2 + this.newsArray.length - numNewsImages) % 3

            this.newsArray.forEach((elem) => {
                var curcol = this.minlen(colLen)
                if (elem.imageName == null) {
                    columns[curcol].push(elem)
                    colLen[curcol] += 1
                } else {
                    if (unevennes > 0) {
                        elem.imageName = null
                        colLen[curcol] += 1
                        unevennes--
                    }
                    else {
                        colLen[curcol] += 2
                    }
                    columns[curcol].push(elem)
                }
            })

            if (colLen[0] != colLen[1]) {
                var shortest = this.minlen(colLen)
                var longest = this.maxlen(colLen)
                var temp = columns[shortest][columns[shortest].length-1]
                columns[shortest][columns[shortest].length-1] = columns[longest][columns[longest].length-1]
                columns[longest][columns[longest].length-1] = temp
            }

            return columns
        }
    },
    methods: {
        minlen(array) {
            var shortest = 0
            for (var i = 1; i < array.length; i++)
                    if (array[i] < array[shortest])
                    shortest = i
            return shortest
        },
        maxlen(array) {
            var longest = 0
            for (var i = 1; i < array.length; i++)
                    if (array[i] > array[longest])
                        longest = i
            return longest
        },
        fetchNews(array, amount) {
            // TODO: make an API call and fetch news 
            var placeHolderNewsObject = {
                title: "Naslov neke vesti",
                text: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, \
                    sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. \
                    At vero eos et accusam et justo duo dolores et ea rebum.Stet clita kasd gubergren, no",
                more: "Pročitaj više",
                link: "https://www.google.com"
            }
            var newsList = []

            var limit = 17 - array.length

            for (var index = 0; index < amount && index < limit; index++) {
                var newElem = { ...placeHolderNewsObject }
                if (Math.random() < 0.33)
                    newElem.imageName = "conference.png"
                newsList.push(newElem)
            }

            return array.concat(newsList)
        },
        loadMoreNews() {
            this.newsArray = this.fetchNews(this.newsArray, this.gridSize);
        }
    }
}
</script>

<style scoped>
.load-more-icon {
    max-width: 200px;
    padding: 16px;
    transition: all .1s ease-in-out;
}
.load-more-icon:hover{
    transform: scale(1.05);
}

.news-bg {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center bottom;
    background-attachment: fixed;
    background-image: url("../assets/newsBg.png");
    min-height: 100vh;
}
.outer-container {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    padding: 16px 0 16px 0
}

.news-grid {
    display: flex;
    flex-flow: row wrap;
}

.news-column {
    flex: 0 0 33%;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
}

@media screen and (max-width: 1100px) {
    .news-column {
        flex: 0 0 100%;
    }
}

.grid-enter-active,
.grid-leave-active {
  transition: all 0.5s ease-in-out;
}
.grid-enter-from,
.grid-leave-to {
  opacity: 0;
  transform: translateY(50px);
}

.news-item {
    margin: 16px 16px 16px 16px;
}
</style>